import {getAllParks} from "@/lib/api";
import Meta from "@/components/Meta";
import {AnimatePresence, motion} from "framer-motion";
import contentTexture from '/public/homepage/paper-texture.png'

import HomepageHero from "@/components/HomepageHero";
import FeaturedParks from "@/components/FeaturedParks";
import FeaturedEvents from "@/components/FeaturedEvents";
import HeadingStyle from "@/components/HeadingStyle";
import EventItemsGrid from "@/components/EventItemsGrid";
import useSWR from "swr";
import {getAllEvents, getEventsAndSortByDate} from "@/lib/data";
import Link from "next/link";
import {ArrowLongRightIcon} from "@heroicons/react/20/solid";
import {parksData} from "../../json/parks";
import {eventData} from "../../json/events2";
import Image from "next/image";
import {useEffect, useState} from "react";

export default function Home({ events, parks }) {
    //const { data: parks, isLoading, error } = useSWR('/api/parks');
    //console.log(parks[1])
    //const designations = [...new Set(parks.map(park => park.designation))];
    //console.log(designations)
    const [width, setWidth] = useState(0)

    const handleResize = () => setWidth(window.innerWidth)
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
    <>
        <Meta title="My National Parks" />
        <main className="">
            <HomepageHero parks={parks} />

            <div className="relative">
                {/*<Image src={contentTexture} alt="" width="1440" height="303" className="absolute -top-[100px] md:-top-[140px] lg:-top-[180px] w-full z-0" placeholder="blur" />*/}
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    className="max-w-8xl mx-auto my-5 px-4 sm:px-6 md:px-8 relative z-1"
                >
                    <FeaturedParks parks={parks} />
                </motion.section>
            </div>

            <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="max-w-8xl mx-auto my-5 px-4 sm:px-6 md:px-8"
            >

                <EventItemsGrid
                    events={events.slice(0,10)}
                    isListView={width >= 768}
                    gridClassNames={'my-5 grid gap-6 my-5 grid-cols-1'}
                    headingClassNames="mt-10 text-4xl"
                    showHeading
                    headingText="Upcoming Events"
                />
                <Link
                    href="/events"
                    type="button"
                    className="inline-flex items-center px-5 py-3 text-xs font-black rounded text-white bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200"
                >
                    Find an event  <ArrowLongRightIcon width={25} className="ml-3 pointer-events-none" />
                </Link>
            </motion.section>

            {/*<NearbyParks parks={parks} />*/}
            {/*<NearbyEvents parks={parks} />*/}
            {/*<FeaturedParks parks={parks} />*/}
            {/*<section className={`max-w-8xl mx-auto my-5 px-4 sm:px-6 md:px-8`}>
                <h2 className={`text-4xl`}>Recent NPS Articles</h2>
            </section>*/}
        </main>
    </>
  )
}

export async function getStaticProps() {
    //const events = await getAllEvents()
    // let eventsData = eventData[0];
    let events = Object.values(eventData);
    events = getEventsAndSortByDate(events, true)

    let parks = Object.values(parksData);

    return {
        props: {
            events,
            parks
        },
    }
}

// export async function getStaticProps() {
//     const parks = await getAllParks()
//     //const articles = await getArticlesForHomepage()
//     return { props: { parks } };
// }
